import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class SkeletalMuscle extends Component {

    render() { const {onClick, fillColor} = this.props
        return (
          <Tooltip
            title="Skeletal Muscle"
            placement="right">
              <g
                id="skeletal_muscle"   
                className="skeletal_muscle"
                fill={fillColor} onClick={onClick}
                fillOpacity="0.5"
                transform="translate(34.453243,16.994796)">
                  <path id="path4883" d="M161.585,423.519c-3.51-12.462-5.706-25.672-5.7-34.291
                      c0.009-12.164,2.8-34.182,7.129-56.24l1.354-6.896l0.229,3.85c0.563,9.441,1.701,19.506,3.174,28.062
                      c2.221,12.856,2.491,28.373,0.773,43.261c-0.9,7.799-3.374,20.666-5.051,26.266l-0.401,1.35L161.585,423.519L161.585,423.519z"/>
                  <path id="path4885" d="M149.519,436.247c-4.27-10.709-6.329-18.451-7.793-29.313
                      c-0.839-6.224-1.011-19.812-0.327-25.827c2.328-20.467,8.664-38.542,20.33-58.011c1.408-2.35,2.627-4.205,2.708-4.124
                      s-0.354,2.438-0.97,5.235c-5.573,25.348-8.974,48.979-9.505,66.044c-0.198,6.354-0.417,8.951-1.059,12.512
                      c-1.593,8.803-2.356,16.652-2.732,27.863C149.969,436.662,149.905,437.215,149.519,436.247L149.519,436.247z"/>
                  <path id="path4887" d="M150.749,474.676c0-2.633-3.106-11.146-5.079-13.923
                      c-0.766-1.078-2.62-6.256-2.62-7.318c0-0.427-0.114-0.777-0.254-0.777c-0.3,0-1.029-2.563-1.029-3.63
                      c0-0.396-0.132-0.87-0.293-1.038s-0.438-1.171-0.617-2.229c-0.178-1.063-0.474-2.502-0.649-3.208
                      c-0.479-1.829-1.558-9.148-2.149-14.598c-0.701-6.396-0.966-24.475-0.477-32.616c0.711-11.871,2.217-24.027,4.521-36.536
                      c1.172-6.349,1.229-6.929,0.938-9.739c-0.412-3.979-1.394-7.962-2.691-10.993c-0.268-0.622,0.122-0.775,2.719-1.081l1.779-0.208
                      l1.021,6.848l1.02,6.846l-1.117,3.369c-2.396,7.245-4.388,16.036-5.504,24.383c-0.829,6.198-0.83,23.063-0.001,29.035
                      c1.657,11.944,4.896,23.548,8.771,31.438c1.357,2.777,1.446,3.148,2.376,9.784c1.104,7.992,2.1,17.397,2.422,23.021l0.225,3.93
                      h-1.646C150.877,475.437,150.749,475.378,150.749,474.676L150.749,474.676z"/>
                  <path id="path4889" d="M155.71,469.382c-0.081-0.081-0.148-1.115-0.148-2.298
                      c0-2.937-1.096-13.729-2.261-22.271c-0.854-6.27-0.96-7.934-0.965-15.23c-0.007-9.809,0.694-18.953,2.198-28.734
                      c0.231-1.55,0.271-1.451,0.972,2.954c1.118,7.021,2.489,13.058,4.759,20.948l2.077,7.219l-0.336,3.688
                      c-0.694,7.624-1.037,20.237-0.727,26.789c0.167,3.529,0.267,6.458,0.223,6.511C161.351,469.122,155.858,469.53,155.71,469.382
                      L155.71,469.382z"/>
                  <path id="path4891" d="M166.068,472.821c-2.396-0.818-2.842-1.092-3.034-1.813
                      c-0.411-1.511-0.278-25.862,0.168-30.693c0.228-2.47,0.464-5.064,0.521-5.771c0.063-0.702,0.772-3.813,1.582-6.896
                      c3.637-13.874,5.494-27.511,6.005-44.112c0.046-1.5,0.8,1.938,2.221,10.104c3.01,17.307,3.992,28.65,3.746,42.989
                      c-0.182,10.563-0.635,15.276-2.144,22.3c-1.359,6.313-4.611,14.668-5.772,14.812C169.093,473.762,167.613,473.354,166.068,472.821z
                      "/>
              </g>
          </Tooltip>
        )
    }
}

export default SkeletalMuscle
