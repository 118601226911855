import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class CirculatorySystem extends Component {

    render() { const {onClick, fillColor} = this.props
        return (
          <Tooltip
            title="Circulatory System"
            placement="right">
              <g
                id="circulatory_system" 
                className="circulatory_system" 
                fill={fillColor} onClick={onClick}
                fillOpacity="0.5"
                transform="translate(118.53498,19.115319)">
                  <path id="path4239" d="M61.632,393.265c0-0.128,0.748-2.486,1.663-5.262
                      c1.72-5.203,3.153-8.979,4.771-12.548c0.901-1.984,0.953-2.173,1.354-4.895c0.756-5.172,1.798-10.271,3.217-15.74
                      c6.197-23.902,17.034-49.145,32.18-74.958c0.979-1.656,1.979-3.547,2.229-4.202c1.332-3.431,2.522-10.141,3.262-18.293
                      c0.695-7.722,0.869-12.195,1.051-26.95c0.231-18.696,0.608-41.88,0.955-58.789l0.303-14.675l0.521-0.462
                      c0.472-0.419,0.604-0.456,1.438-0.395c0.604,0.044,1.275,0.241,1.959,0.578l1.039,0.511l0.105,4.533
                      c0.508,21.432,0.656,47.017,0.418,72.068c-0.162,17.08-0.165,22.67-0.015,25.747c0.382,7.809,1.113,13.11,2.313,16.624
                      c0.215,0.629,1.217,2.542,2.229,4.251c18.505,31.353,31.468,64.666,35.314,90.773c0.363,2.461,0.449,2.771,1.207,4.387
                      c1.699,3.62,3.646,8.831,5.693,15.229l0.858,2.695l-0.893-0.048l-0.89-0.048l-0.835-2.166c-1.542-4.014-3.993-9.443-4.361-9.671
                      c-0.416-0.267-0.492,0.146-0.271,1.48c0.272,1.71,0.808,6.226,0.938,8.021c0.055,0.75,0.137,1.598,0.18,1.885l0.08,0.521h-2.719
                      h-2.72l-0.091-1.808c-0.054-0.992-0.169-3.465-0.271-5.494c-0.429-9.05-0.47-9.315-2.672-17.237
                      c-9.479-34.122-22.88-65.475-35.646-83.417c-0.852-1.191-1.541-2.312-1.541-2.486c0-0.673-0.488-0.261-1.723,1.444
                      c-10.14,14.074-20.71,36.461-29.505,62.48c-2.411,7.132-5.894,18.665-7.817,25.905c-0.705,2.646-0.846,3.427-1.055,5.937
                      c-0.31,3.714-0.528,7.533-0.669,11.513l-0.106,3.175H70.43h-2.702v-0.441c0-0.448,0.409-4.884,0.557-6.056
                      c0.045-0.354,0.231-1.722,0.414-3.024c0.319-2.28,0.324-2.831,0.025-2.831c-0.334,0-3.13,6.108-4.7,10.271l-0.757,2.005
                      l-0.818,0.049C61.821,393.49,61.632,393.449,61.632,393.265L61.632,393.265z"/>
                  <path id="path4241" d="M72.313,630.377c-0.699-3.412-2.394-12.848-3.292-18.327
                      c-0.622-3.794-1.28-7.729-1.464-8.757c-0.897-5.008-0.556-10.239,0.914-14.018c0.31-0.797,0.6-1.604,0.644-1.777
                      s-0.184-1.588-0.508-3.128c-1.755-8.346-2.496-18.558-2.002-27.595c0.63-11.523,2.641-21.792,6.327-32.313
                      c1.896-5.415,3.702-16.56,4.61-28.474c0.271-3.565,0.271-15.486,0.001-19.009c-0.537-6.978-1.458-12.528-3.164-19.099
                      c-2.587-9.941-4.848-22.971-5.999-34.646c-0.742-7.483-0.89-10.57-0.891-19.41c-0.001-4.676,0.063-8.917,0.134-9.424l0.128-0.922
                      h2.688h2.69l-0.075,5.173c-0.135,9.396,0.41,21.785,1.419,32.203c0.684,7.063,1.93,16.354,2.568,19.139
                      c1.438,6.313,2.75,14.446,3.595,22.408c1.252,11.809,1.489,21.226,0.738,29.436c-1.354,14.813-3.497,25.876-6.328,32.673
                      l-0.579,1.396l0.784,2.071c1.84,4.854,4.304,13.351,4.809,16.562c2.404,15.312,3.702,38.119,3.706,65.169l0.004,11.19h-0.481H82.81
                      l-0.037-2.286c-0.02-1.257-0.015-2.813,0.011-3.455c0.073-1.771-0.271-15.159-0.542-20.888
                      c-0.522-11.352-1.455-24.563-2.688-38.418c-0.572-6.402-0.712-7.555-0.934-7.595c-0.211-0.041-0.301,0.313-0.525,2.084
                      c-1.092,8.366-3.456,18.599-6.166,26.646l-0.961,2.854L72,595.736c1.302,7.383,4.729,27.52,5.36,31.472
                      c0.268,1.632,0.519,3.134,0.563,3.338c0.079,0.354,0.042,0.367-0.601,0.321l-0.679-0.05l-0.709-4.018
                      c-0.39-2.203-1.912-11.229-3.382-20.053c-2.156-12.936-2.722-16.049-2.922-16.088c-0.771-0.146-1.546,6.317-1.345,11.194
                      c0.099,2.35,0.352,4.096,2.26,15.544c1.182,7.093,2.146,13.025,2.146,13.188C72.701,631.093,72.428,630.935,72.313,630.377
                      L72.313,630.377z M71.168,583.229c1.555-4.729,3.028-11.411,4.097-18.503c0.563-3.771,1.286-10.023,1.286-11.146
                      c0-1.034-0.946-5.587-1.771-8.505c-0.686-2.44-2.047-6.571-2.229-6.778c-0.153-0.171-1.645,4.011-2.407,6.778
                      c-3.144,11.323-3.377,24.709-0.656,37.778c0.485,2.33,0.648,2.859,0.815,2.692C70.384,585.473,70.773,584.428,71.168,583.229
                      L71.168,583.229z"/>
                  <path id="path4243" d="M143.531,625.885c-0.174-7.794,0.197-27.384,0.726-37.978
                      c0.63-12.802,1.813-25.746,3.063-33.366c0.51-3.119,3.563-13.417,5.082-17.128l0.479-1.166l-0.563-1.32
                      c-2.727-6.396-4.977-17.851-6.293-32.04c-0.811-8.729-0.643-17.338,0.568-29.188c0.815-8.06,2.242-17.146,3.536-22.619
                      c1.042-4.417,2.646-17.479,3.341-27.19c0.615-8.617,0.963-20.201,0.791-26.428l-0.109-3.97h2.729h2.727l0.104,1.457
                      c0.232,3.349,0.303,11.773,0.113,15.857c-0.729,16.421-2.896,31.396-6.832,47.242c-1.525,6.172-1.979,8.459-2.557,13.011
                      c-0.771,6.108-0.937,9.559-0.843,17.313c0.117,9.639,0.668,15.782,2.271,25.435c0.916,5.482,1.574,8.245,2.861,12.005
                      c1.838,5.359,3.244,10.684,4.191,15.819c2.271,12.355,2.521,26.099,0.706,38.104c-0.327,2.146-1.377,7.479-1.521,7.719
                      c-0.041,0.063,0.11,0.541,0.34,1.054c0.674,1.521,1.328,3.94,1.609,5.95c0.313,2.229,0.229,6.646-0.158,8.458
                      c-0.135,0.618-0.574,3.216-0.979,5.771c-1.354,8.475-3.777,21.968-3.994,22.185c-0.058,0.05-0.146,0.04-0.202-0.021
                      c-0.063-0.063,0.886-6.094,2.104-13.396l2.225-13.29l-0.081-4.396c-0.079-4.308-0.17-5.298-0.703-7.642
                      c-0.202-0.889-0.329-1.163-0.539-1.163c-0.238,0-0.595,1.938-2.794,15.199c-1.386,8.358-2.896,17.329-3.354,19.932l-0.838,4.732
                      l-0.683,0.05c-0.646,0.047-0.676,0.025-0.604-0.321c0.047-0.198,0.949-5.523,2.015-11.84c1.058-6.308,2.468-14.57,3.133-18.368
                      c0.665-3.794,1.332-7.643,1.479-8.553l0.271-1.655l-1.069-3.229c-2.775-8.411-5.25-19.302-6.176-27.225
                      c-0.094-0.807-0.188-1.056-0.4-1.098c-0.188-0.037-0.273,0.047-0.273,0.257c0,0.178-0.322,3.912-0.722,8.313
                      c-2.046,22.896-3.13,42.864-3.134,57.771l-0.002,5.979h-0.464h-0.462L143.531,625.885L143.531,625.885z M157.61,584.169
                      c1.369-5.817,2.043-12.082,2.063-19.121c0.013-7.617-0.74-13.438-2.599-20.048c-0.562-2.008-1.901-5.934-2.188-6.395
                      c-0.211-0.352-2.521,7.062-3.313,10.642c-0.942,4.258-0.938,4.093-0.416,8.743c0.955,8.479,2.232,15.62,3.938,21.977
                      c0.646,2.438,1.836,6.021,1.979,6.021C157.135,585.982,157.374,585.166,157.61,584.169L157.61,584.169z"/>
                  <path id="path4245" d="M167.038,468.137c-0.008-0.099,0.1-1.218,0.229-2.479
                      c1.239-11.938,2.323-33.771,2.326-46.856c0.001-4.881-0.003-4.944-0.486-7.219c-0.562-2.646-1.562-6.678-2.257-9.109
                      c-0.429-1.499-2.597-8.27-2.813-8.774c-0.1-0.233,1.166-0.282,1.521-0.063c0.271,0.172,1.321,3.793,2.75,9.484
                      c1.845,7.345,2.195,9.165,2.409,12.512c0.287,4.485,0.395,19.254,0.172,23.563c-0.542,10.479-1.632,19.229-3.37,27.056
                      C167.262,467.379,167.047,468.236,167.038,468.137L167.038,468.137z"/>
                  <path id="path4247" d="M60.046,467.194c-0.017-0.088-0.313-1.563-0.658-3.276
                      c-2.752-13.414-3.779-30.908-2.798-47.642c0.203-3.463,0.316-4.465,0.795-6.657c0.727-3.377,2.094-8.83,3.229-12.887l0.911-3.248
                      h0.835h0.835l-0.713,2.206c-2.096,6.479-3.221,10.605-4.313,15.834l-0.574,2.729l0.179,8.503
                      c0.336,15.996,1.137,31.188,2.192,41.586c0.136,1.354,0.215,2.571,0.174,2.729C60.098,467.227,60.057,467.283,60.046,467.194
                      L60.046,467.194z"/>
                  <path id="path4251" d="M153.363,638.553c-0.004-0.146,1.209-7.854,1.561-9.854
                      c0.292-1.694,0.294-1.521,0.004,0.453c-0.11,0.748-0.2,1.438-0.2,1.542c0,0.209,0.082,0.276,0.201,0.187
                      c0.063-0.055,0.083-0.052,0.083,0.021c0,0.188-1.526,7.516-1.613,7.729C153.379,638.653,153.365,638.621,153.363,638.553
                      L153.363,638.553L153.363,638.553z"/>
                  <path id="path4253" d="M147.121,675.908c-2.029-0.179-3.391-1.129-3.633-2.549
                      c-0.103-0.586-0.135-2.566-0.061-3.297c0.219-2.07,0.904-3.906,2.086-5.567c0.527-0.74,1.869-2.039,2.438-2.356
                      c0.063-0.026,0.021-0.179-0.229-0.706c-0.814-1.714-1.313-3.427-1.604-5.563c-0.133-0.979-0.156-3.771-0.053-4.854
                      c0.066-0.675,3.24-20.104,3.295-20.178c0.008-0.007,0.074,0.007,0.146,0.033c0.215,0.078,1.181-0.001,1.243-0.104
                      c0.03-0.061,0.054-0.063,0.055-0.013c0.004,0.313-2.271,12.566-3.016,16.263c-0.644,3.146-0.597,2.869-0.679,4.025
                      c-0.266,3.729,0.791,7.956,2.754,11.003c0.271,0.424,0.332,0.48,0.908,0.847c4.642,2.938,8.064,6.071,9.08,8.308l0.193,0.426
                      l-0.099,0.46c-0.268,1.3-0.752,2.238-1.299,2.496c-0.104,0.05-0.331,0.091-0.502,0.091c-0.172,0-2.338,0.278-4.813,0.618
                      C148.699,675.922,148.033,675.989,147.121,675.908L147.121,675.908z M153.026,674.411c3.205-0.386,3.438-0.43,4.043-0.79
                      c0.664-0.388,1.188-1.172,1.363-2.035c0.08-0.393,0.081-0.479,0.006-0.801c-0.464-2.001-3.007-4.324-6.838-6.246
                      c-0.951-0.477-2.324-1.104-2.418-1.104c-0.08,0-0.656,0.631-1.119,1.225c-1.113,1.43-2.144,3.485-2.617,5.215
                      c-0.293,1.074-0.43,1.965-0.508,3.323l-0.033,0.547l0.283,0.181c0.604,0.395,1.861,0.733,2.952,0.814
                      C149.285,674.824,149.988,674.777,153.026,674.411L153.026,674.411z"/>
                  <path id="path4255" d="M141.279,658.485c0-0.021,0.049-0.374,0.107-0.789
                      c0.165-1.127,0.602-4.627,0.794-6.398c0.786-7.135,1.21-13.574,1.29-19.595c0.021-1.561,0.059-2.772,0.078-2.694
                      c0.019,0.078,0.043,0.544,0.046,1.035l0.009,0.896h0.485h0.48l-0.026,0.577c-0.021,0.314-0.063,1.271-0.101,2.107
                      c-0.301,7.895-1.139,15.666-2.301,21.313C141.82,656.556,141.279,658.753,141.279,658.485L141.279,658.485z"/>
                  <path id="path4257" d="M85.736,657.097c-1.497-6.062-2.523-14.716-2.872-24.024
                      c-0.025-0.873-0.063-1.759-0.08-1.957c-0.012-0.203-0.01-0.324,0.004-0.271c0.022,0.085,0.103,0.104,0.517,0.104h0.479
                      l-0.035-11.896c-0.019-6.543-0.05-12.675-0.063-13.626c-0.021-0.949-0.021-1.717-0.005-1.699c0.082,0.08,0.174,10.021,0.18,19.431
                      c0.007,10.688,0.015,11.153,0.23,15.107c0.322,6.034,1.015,13.146,1.813,18.854c0.078,0.557,0.132,1.021,0.12,1.036
                      C86.009,658.15,85.88,657.681,85.736,657.097L85.736,657.097L85.736,657.097z"/>
                  <path id="path4259" d="M74.015,674.971c-2.542-0.354-4.704-0.636-4.804-0.622
                      c-0.807,0.107-1.489-0.856-1.858-2.624l-0.087-0.416l0.222-0.474c1.044-2.229,4.354-5.257,8.948-8.195l0.747-0.478l0.345-0.552
                      c1.95-3.124,2.979-7.392,2.674-11.077c-0.043-0.523-0.167-1.331-0.315-2.104c-0.528-2.604-0.999-5.023-1.521-7.818
                      c-0.548-2.938-1.791-9.808-1.79-9.89c0-0.021,0.025-0.007,0.062,0.041c0.066,0.116,1.046,0.188,1.252,0.092l0.144-0.063
                      l0.357,2.159c0.189,1.188,0.896,5.438,1.563,9.438c0.666,4.018,1.239,7.53,1.273,7.827c0.121,1.037,0.169,2.604,0.113,3.688
                      c-0.146,2.771-0.68,4.998-1.725,7.215L79.3,661.78l0.23,0.153c1.667,1.099,3.188,3.218,3.865,5.395
                      c0.429,1.374,0.567,2.48,0.525,4.208c-0.045,1.83-0.161,2.233-0.839,2.916c-0.615,0.618-1.5,0.979-2.739,1.117
                      C79.27,675.688,78.867,675.65,74.015,674.971L74.015,674.971z M79.518,674.378c0.998-0.124,1.684-0.307,2.342-0.623
                      c0.614-0.296,0.574-0.188,0.499-1.268c-0.146-2.093-0.604-3.771-1.546-5.649c-0.65-1.298-1.412-2.415-2.358-3.457l-0.274-0.31
                      l-0.91,0.41c-0.821,0.37-2.465,1.202-3.05,1.544c-2.33,1.354-4.153,2.998-4.932,4.422c-0.548,1.012-0.569,1.671-0.104,2.659
                      c0.354,0.757,1.104,1.346,2.004,1.563c0.201,0.058,1.591,0.24,3.088,0.42C77.186,674.448,78.42,674.513,79.518,674.378
                      L79.518,674.378z"/>
                  <path id="path4261" d="M73.515,636.395c-0.552-2.559-1.146-5.436-1.146-5.563
                      c0-0.08,0.021-0.079,0.115,0.02c0.153,0.139,0.215,0.063,0.236-0.284c0.012-0.189,0.229,1.081,0.66,3.813
                      c0.353,2.256,0.632,4.113,0.62,4.125C73.988,638.522,73.77,637.571,73.515,636.395L73.515,636.395z"/>
                  <path id="path4269" d="M-42.48,339.176c-2.059-0.554-4.361-2.199-6.492-4.637
                      c-2.043-2.337-3.021-4.836-2.769-7.068c0.13-1.146,0.749-3.178,1.175-3.85c0.167-0.265,3.417-4.084,7.221-8.488
                      c3.811-4.402,6.917-8.062,6.917-8.132c0-0.409-0.424-0.209-1.222,0.576c-1.678,1.651-4.252,3.271-6.879,4.333
                      c-1.543,0.623-4.458,1.429-5.775,1.597c-0.718,0.092-0.625,0.033,0.882-0.547c4.271-1.646,9.451-4.6,12.438-7.089
                      c2.479-2.068,5.873-5.888,9.45-10.642c10.148-13.495,20.813-32.292,31.225-55.07c2.419-5.292,3.17-6.55,5.152-8.624
                      c3.186-3.336,7.347-5.414,12.159-6.072l1.355-0.186l0.938-2.322c1.188-2.949,3.979-11.322,5.135-15.395
                      c3.05-10.797,5.421-22.066,7.003-33.286c0.924-6.554,1.872-11.27,3.869-19.25c1.813-7.251,4.99-17.766,7.799-25.827l0.95-2.727
                      l2.436-0.044l2.438-0.044l-0.531,0.695c-1.771,2.325-5.479,11.497-8.053,19.927c-2.616,8.574-6.5,24.172-6.5,26.101
                      c0,0.994-0.419,3.947-0.984,6.944c-1.858,9.872-5.827,24.251-11.051,40.089l-1.741,5.279l0.521,0.657
                      c1.266,1.599,2.311,3.767,2.834,5.879c0.646,2.617,0.6,6.368-0.122,9.279c-0.243,0.979-0.354,1.131-2.368,3.289
                      c-2.284,2.444-6.879,6.793-10.348,9.793c-2.283,1.975-3.278,3.019-6.969,7.307C-2.48,273.379-12.934,288-21.054,301.724
                      c-3.228,5.456-5,10.319-5.996,16.455c-0.605,3.769-1.48,6.132-3.583,9.669c-1.854,3.138-4.388,6.482-7.442,9.849
                      C-39.704,339.479-40.438,339.726-42.48,339.176L-42.48,339.176L-42.48,339.176z M-39.689,338.231
                      c1.296-0.604,2.757-2.22,4.067-4.495c0.438-0.773,1.857-2.896,3.148-4.717c1.29-1.822,2.48-3.506,2.646-3.74
                      c0.396-0.574,1.312-4.202,1.729-6.872c0.606-3.87,0.849-5.021,1.46-7.141c1.909-6.535,5.62-13.354,10.042-18.446
                      c0.46-0.529,2.097-2.875,3.623-5.214c10.246-15.628,18.921-27.065,26.022-34.309c3.5-3.57,6.747-6.162,8.299-6.627
                      c1.283-0.385,3.64-3.952,4.583-6.94c0.357-1.134,0.409-1.58,0.413-3.619c0.003-1.905-0.063-2.558-0.348-3.609
                      c-0.402-1.472-1.488-3.695-2.429-4.959l-0.641-0.867l-1.008,0.078c-5.604,0.43-10.275,3.042-13.501,7.542
                      c-0.771,1.07-2.021,3.392-2.495,4.623c-0.822,2.13-5.258,11.493-8.779,18.528c-8.371,16.726-15.71,29.162-22.766,38.58
                      c-2.563,3.421-4.18,5.324-6.459,7.62c-1.103,1.103-2.369,2.51-2.828,3.128c-0.459,0.618-3.815,4.387-7.457,8.376
                      c-4.926,5.395-6.726,7.471-7.021,8.101c-0.755,1.613-1.021,2.721-1.116,4.547c-0.159,3.112,0.56,5.005,2.664,7.019
                      c1.486,1.425,2.344,2.021,4.315,3.026C-41.718,338.76-40.984,338.834-39.689,338.231L-39.689,338.231L-39.689,338.231z"/>
                  <path id="path4271" d="M268.362,339.266c-0.338-0.084-0.784-0.392-1.122-0.772
                      c-0.311-0.349-1.075-1.21-1.703-1.913c-4.127-4.63-7.504-9.873-8.976-13.936c-0.354-0.984-0.73-2.554-1.037-4.369
                      c-1.045-6.146-2.823-11.156-5.68-15.989c-9.18-15.521-20.812-31.518-32.406-44.564c-0.532-0.6-2.162-2.143-3.616-3.428
                      c-4.38-3.866-8.087-7.38-10.339-9.801l-2.131-2.29l-0.27-1.239c-0.729-3.444-0.735-6.306-0.025-9.088
                      c0.469-1.839,1.51-4.079,2.547-5.489l0.724-0.981l-1.189-3.668c-4.854-14.889-8.063-25.986-10.229-35.431
                      c-1.316-5.742-2.348-11.65-2.348-13.484c0-1.307-3.241-14.736-5.221-21.634c-2.646-9.197-5.401-16.76-7.91-21.668
                      c-0.586-1.146-1.272-2.319-1.524-2.607l-0.457-0.523h2.479h2.478l2.271,6.778c5.26,15.743,8.557,28.799,10.3,40.786
                      c2.749,18.912,7.152,36.52,12.411,49.566l0.713,1.762l1.42,0.195c5.635,0.772,10.417,3.553,13.729,7.986
                      c1.129,1.512,1.383,2.005,4.047,7.866c4.156,9.145,11.214,22.974,15.763,30.88c9.561,16.628,19.355,29.952,25.317,34.446
                      c3.063,2.303,7.146,4.559,11.063,6.106c2.294,0.908,2.288,1-0.028,0.498c-4.299-0.931-8.403-2.979-11.135-5.556
                      c-0.826-0.779-1.169-1.011-1.303-0.879c-0.138,0.132,0.022,0.422,0.604,1.101c0.435,0.508,3.596,4.176,7.024,8.148
                      c6.699,7.766,6.93,8.077,7.413,10.369c0.466,2.184-0.021,4.562-1.313,6.531c-0.313,0.47-1.302,1.599-2.199,2.511
                      c-1.645,1.664-3.181,2.784-4.693,3.42C270.865,339.297,269.186,339.474,268.362,339.266L268.362,339.266L268.362,339.266z
                      M271.997,337.819c1.991-1.018,3.896-2.438,4.985-3.729c1.463-1.729,2.04-3.389,2.02-5.815c-0.014-1.604-0.298-2.889-1.02-4.622
                      c-0.451-1.083-0.904-1.619-7.16-8.454c-3.672-4.014-7.013-7.76-7.42-8.326s-1.614-1.902-2.682-2.968
                      c-7.355-7.357-16.509-21.41-26.7-41c-3.525-6.774-10.482-21.238-11.459-23.824c-1.752-4.642-5.391-8.769-9.316-10.579
                      c-2.104-0.97-4.438-1.572-6.771-1.746l-1.005-0.075l-0.65,0.907c-0.932,1.297-2.021,3.585-2.416,5.078
                      c-0.494,1.864-0.505,4.822-0.021,6.588c0.623,2.283,1.607,4.162,3.313,6.286c0.439,0.551,0.915,1.004,1.055,1.006
                      c0.146,0.002,0.643,0.215,1.104,0.473c6.986,3.853,19.416,18.922,33.87,41.071c1.354,2.073,2.87,4.239,3.371,4.812
                      c2.554,2.921,5.521,7.483,7.146,11.002c2.326,5.016,3.442,8.747,4.246,14.183c0.188,1.279,0.597,3.33,0.903,4.56l0.563,2.23
                      l2.688,3.784c1.479,2.081,2.931,4.217,3.227,4.746c1.312,2.35,2.95,4.224,4.243,4.833
                      C269.401,338.843,270.156,338.761,271.997,337.819L271.997,337.819L271.997,337.819z"/>
                  <path id="path4273" d="M113.319,130.899c-1.226-0.314-2.021-1.04-2.554-2.331
                      c-0.325-0.787-1.124-1.764-3.997-4.888c-2.577-2.802-3.952-3.83-7.593-5.676c-8.854-4.486-17.124-5.852-25.398-4.192
                      c-6.813,1.367-13.188,4.961-18.918,10.663l-1.862,1.854l-2.466,0.044c-2.336,0.042-2.46,0.029-2.35-0.25
                      c0.063-0.162,0.479-1.305,0.926-2.54c0.949-2.629,1.32-3.182,3.967-5.911c8.645-8.904,19.898-12.747,31.53-10.761
                      c6.695,1.143,13.759,4.146,20.749,8.82c1.784,1.193,2.214,1.287,2.344,0.513c1.146-6.809-0.123-19.198-3.141-30.76
                      c-1.707-6.536-4.79-15.924-6.506-19.811c-0.843-1.909-1.589-3.873-2.563-6.737c-2.759-8.145-4.427-15.957-5.027-23.581
                      c-0.104-1.235-0.285-3.488-0.416-5.006c-0.842-9.732,0.418-17.557,3.936-24.567c1.528-3.045,4.422-6.86,6.564-8.654
                      c0.779-0.658,0.859-0.688,1.731-0.642l0.915,0.048l-1.346,0.554c-1.161,0.478-1.524,0.732-2.646,1.845
                      c-4.291,4.272-6.938,11.971-7.606,22.145c-0.154,2.32-0.067,4.394,0.396,9.465c1.156,12.599,3.354,23.007,6.247,29.677
                      c0.938,2.145,2.021,4.12,2.317,4.22c0.318,0.107,1.642-1.397,2.478-2.823c0.436-0.731,1.101-2.067,1.479-2.968
                      c0.803-1.879,0.88-1.821,0.293,0.22c-0.82,2.849-1.997,5.672-3.058,7.324l-0.582,0.911l1.543,4.739
                      c4.655,14.308,7.801,27.774,8.849,37.944c0.136,1.323,0.293,3.868,0.349,5.655l0.104,3.25l0.647,0.176
                      c1.611,0.434,3.459-0.202,4.402-1.515l0.547-0.763l-0.263-1.316c-0.853-4.278-0.733-11.135,0.312-18.881
                      c1.063-7.888,3.082-17.333,5.955-27.872l1.063-3.89l-0.567-0.855c-0.82-1.235-1.388-2.408-1.908-3.948
                      c-0.623-1.84-0.564-2.128,0.104-0.585c0.307,0.698,0.813,1.673,1.146,2.166c0.662,1,2.437,2.783,2.631,2.658
                      c0.246-0.152,1.738-4.158,2.545-6.836c3.313-10.993,5.01-23.357,5.267-38.499c0.104-6.673,0.065-7.181-0.834-10.49
                      c-0.824-3.007-2.673-7.396-3.849-9.116c-0.479-0.718-0.276-0.76,0.396-0.081c2.148,2.148,4.136,6.9,4.955,11.848
                      c0.546,3.296,0.661,5.081,0.661,10.266c0,17.143-2.613,32.797-7.15,42.855c-2.41,5.337-5.228,16.716-6.469,26.125
                      c-0.783,5.95-1.234,13.598-1.07,18.207c0.131,3.695,0.445,8.217,0.59,8.449c0.08,0.129,0.902-0.325,2.578-1.423
                      c3.146-2.064,4.443-2.8,7.688-4.362c9.347-4.506,18.459-5.709,26.854-3.545c6.113,1.575,11.979,4.998,16.711,9.753
                      c2.86,2.877,3.354,3.583,4.313,6.173c0.461,1.254,0.889,2.442,0.95,2.64l0.104,0.361h-2.396h-2.396l-1.826-1.808
                      c-3.846-3.805-7.131-6.169-11.401-8.208c-4.666-2.225-8.979-3.193-14.24-3.197c-5.09-0.004-9.527,0.877-14.604,2.898
                      c-3.819,1.526-8.758,4.304-9.75,5.494l-0.521,0.632l0.589,0.771c1.287,1.684,1.751,3.807,1.375,6.305
                      c-0.188,1.256-1.295,1.546-3.256,0.854l-0.741-0.261l-0.097-1.034c-0.162-1.73-1.229-3.466-2.545-4.148
                      c-0.402-0.209-0.521-0.207-0.979,0.012c-1.078,0.515-1.662,1.933-1.826,4.438c-0.063,1.005-0.172,1.594-0.299,1.642
                      C114.969,130.947,113.774,131.015,113.319,130.899L113.319,130.899L113.319,130.899z"/>
              </g>
          </Tooltip>
        )
    }
}

export default CirculatorySystem
