import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class Colon extends Component {

    render() { const {onClick, fillColor} = this.props
        return (
          <Tooltip
            title="Colon"
            placement="right">
              <path
                id="colon"
                className="colon"
                fill={fillColor} onClick={onClick}
                fillOpacity="0.5"
                stroke="#787878"
                strokeWidth="0.5"
                d="M279.948,288.077l-0.987-4.124
                  l0.662-2.406v-6.873l0.325-3.093l-0.325-1.375v-6.874l-0.33-1.375v-5.154l-0.332-1.375l-0.993-2.406l-0.993-1.717l-0.662-0.688
                  l-1.988-0.688h-1.981l-1.656,0.688h-2.317l-2.313,2.063l-1.985,1.03l-2.316,1.718l-1.985,1.719l-0.994,1.375l-3.313,1.718
                  l-1.651,1.718l-0.663,1.031l-2.315,1.375l-1.656,1.718l-0.329,0.345l-0.993,0.344l-6.628,2.75l-2.316,0.344l-3.313,1.375h-1.986
                  l-2.318-0.688l-2.318-0.343l-1.986-1.03L225,268.491l-2-0.344l-1.656-0.688l-1.986-0.344l-2.315-1.031l-2.319-0.688l-1.324-0.687
                  l-1.653-0.687l-1.324-0.688l-1.656-1.375l-1.657-1.031l-1.32-0.687l-1.657-0.688l-3.646,0.344H198.5l-1.656,1.031l-1.656,1.03
                  l-2.316,2.062l-0.662,2.063l-1.323,2.063l-1.325,1.031l-1.324,2.748l-1.984,4.813l-0.331,2.749l-2.649,4.124l-0.993,2.406
                  l-0.331,3.437l-0.33,4.124l-0.993,3.093v5.156l0.662,2.062l0.331,2.406l0.662,4.469l0.993,3.779l2.317,4.469l3.979,2.405
                  l3.313,1.375h3.646l3.313-1.03l-0.009-3.095l0.33-2.062l-0.66-3.096l-0.331-1.718l-0.993-2.062v-3.438l-1.656-3.093l-0.663-1.031
                  l-0.662-4.123l0.662-2.404v-3.438l0.663-1.375v-2.405l2.649-2.063v-2.063l0.661-1.031l0.33-3.092l1.324-1.375l0.663-2.406
                  l-0.785-0.977l3.771,0.977l1.655,2.062l3.313,0.345l1.986,0.688l2.979,1.03h3.313l2.979,1.375l2.98,1.031h1.325l2.646,0.344
                  l2.98,1.031l2.979,0.344h3.646l1.322-0.344l2.979-1.375h3.313l1.984-2.063h2.648l1.653-1.031h1.654l2.649-1.375l3.313-0.344
                  l1.985-2.406l2.979-0.688l2.648-2.405l1.983-0.345l-0.658,2.063l0.99,2.406l-0.99,2.406v4.123l0.658,1.719l-0.658,2.406v2.748
                  l-0.993,1.375l0.329,2.406l-0.993,2.406l0.332,2.406v1.718l-0.993,1.718l-1.323,1.031v2.406l-1.658,1.718v1.718l-1.652,2.406
                  l-0.661,2.406l-1.654,1.718l-1.986,1.718l-0.66,2.063h-2.317l-0.661,1.718l-2.317,0.343h-2.315l-2.646,1.718H239.9l-1.654,1.718
                  l-1.655,0.688h-4.971l-1.656,0.687l-1.987,0.347l-0.993,1.718h-2.315l-2.318,1.031l-0.331,2.404l-0.102,0.13
                  c-0.239,2.772-0.256,6.608,0.665,9.534c4.396-0.555,8.8-0.787,13.218-0.863c0.131-0.386,0.308-0.764,0.521-1.123l-0.392-0.115
                  l-0.661-1.718l4.313-1.72l1.319-1.029l1.323-0.346l2.318-0.687l1.322-2.063l2.649-0.346h1.655l2.314-0.343l3.313-1.031l2.316-0.343
                  l2.317-0.344l1.656-2.406h2.646l1.656-2.063l1.323-1.375l2.646-3.438v-1.031l0.994-0.687v-1.375l2.319-2.406v-1.375l2.315-2.749
                  l0.329-3.093l1.987-2.749l0.993-2.063l-0.33-2.404l0.993-2.405l-0.331-4.468v-3.094L279.948,288.077z"/>
          </Tooltip>
        )
    }
}

export default Colon
