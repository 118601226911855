import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class SmallIntestine extends Component {

    render() { const {onClick, fillColor} = this.props
        return (
          <Tooltip
            title="Small Intestine"
            placement="right">
              <path
                id="small_intestine" 
                className="small_intestine"
                fill={fillColor} onClick={onClick}
                fillOpacity="0.5"
                stroke="#787878"
                strokeWidth="0.5"
                d="M261.399,283.242
                  c0.482-0.575,1.416-0.613,2.291-0.739c1.287-1.915,3.82-2.005,5.621-3.457c2.87-2.313,2.037-8.969-2.164-10.227
                  c-3.76-1.127-7.744,0.939-10.297,2.78c-2.34,1.687-5.088,3.957-4.938,6.523c-0.027-0.46-0.063-1.082-0.234-1.5
                  c-0.855,0.188-1.716,0.717-2.707,0.76c1.088-0.327,2.342-0.667,3.189-1.279c1.709-1.234,2.854-3.667,2.94-5.402
                  c0.091-1.813-1.311-3.036-3.755-3.39c-6.172-0.891-9.287,3.629-14.789,3.76c-2.092,0.049-3.98-0.627-6.054-0.543
                  c-2.54,0.104-4.782,1.085-7.03,1.851c-1.938,0.66-4.617,1.632-6.75,0.972c-0.771-0.239-1.531-0.558-2.251-0.872
                  c-0.934-0.408-1.948-0.906-2.261-1.71c0.546,1.049-0.828,1.926-2.153,2.093c-1.457,0.186-3.106,0.478-4.595,0.264
                  c1.492,1.918,3.104,3.736,6.083,4.507c1.937,0.501,4.188,0.85,6.222,0.858c1.204,0.006,2.372-0.073,3.563-0.22
                  c0.896-0.109,1.914-0.156,2.597-0.633c0.531-0.369,0.695-0.731,1.468-0.928c0.92-0.234,1.969-0.286,2.934-0.382
                  c1.043-0.104,2.209-0.097,3.254-0.001c1.303,0.121,2.498,0.533,3.778,0.714c1.01,0.142,1.981,0.215,2.979,0.409
                  c0.807,0.156,2.703,0.837,3.353,0.23c0.334-0.313,0.479-0.726,0.864-1.044c0.456-0.371,1.021-0.686,1.524-1.027
                  c1.289-0.873,2.479-1.853,3.479-2.893c0.588-0.615,0.494-1.618,0.271-2.345c0.257,0.563,0.104,1.193,0.476,1.733
                  c0.155-0.37,2.029-0.497,2.563-0.728c-1.063,0.192-2.205,0.521-2.979,1.072c-1.096,0.787-1.792,1.95-2.789,2.812
                  c-0.896,0.777-3.417,1.356-3.229,2.581c0.102,0.68,0.938,0.794,1.745,1.125c1.281,0.53,2.585,1.045,3.882,1.567
                  c1.504,0.604,5.412,1.496,6.897,0.527c0.95-0.618,1.868-1.569,2.734-2.269c2.286-1.847,5.331-3.432,6.687-5.777
                  c-0.214,0.838-1.587,2.065-2.173,2.93c1.102-0.681,2.112-1.169,3.58-1.321c-3.214,0.543-4.679,1.738-6.749,3.405
                  c-1,0.804-1.852,1.692-2.812,2.509c-0.521,0.439-1.212,0.81-1.652,1.302c-0.39,0.434-0.306,0.733-0.339,1.222
                  c-0.053,0.798-0.619,1.282-0.95,2.023c-0.634,1.413,1.007,2.146,2.546,2.647c0.866,0.28,1.771,0.507,2.604,0.841
                  c0.328,0.132,0.915,0.314,1.157,0.515c0.471,0.385,0.194,0.86,0.354,1.316c0.179,0.533,0.747,1.008,0.913,1.569
                  c0.163,0.563,0.178,1.184-0.156,1.705c-0.331,0.518-1.098,0.772-1.363,1.28c1.926,1.194,6.48,2.127,7.895,0.199
                  c0.457-0.625,0.503-1.38,0.563-2.069c0.086-0.931,0.205-1.894,0.113-2.832c-0.063-0.683-0.24-1.363-0.345-2.042
                  c-0.077-0.5-0.479-1.333-0.319-1.811c0.103-0.296,0.732-0.653,1.004-0.91c0.604-0.574,1.105-1.195,1.783-1.729
                  c-1.166,0.75-2.563,1.931-2.5,3.12c0.072,1.299,0.148,2.62,0.22,3.929c0.104,1.825-0.347,3.593-1.343,5.273
                  c-0.969,1.637-3.203,3.309-2.639,5.193c0.155-1.75,0.687-3.916,2.48-5.223c-0.545,0.396-2.193,0.334-2.889,0.279
                  c-1.07-0.085-1.959-0.379-2.863-0.783c-1.688-0.753-2.893,0.029-3.58,1.237c-0.479,0.841-0.174,1.924-0.102,2.801
                  c0.078,0.9-0.311,1.794,0.24,2.626c0.516,0.783,1.424,1.507,1.836,2.302c-0.271-0.378-0.565-0.744-0.887-1.109
                  c-0.197-0.229-0.875-1.173-1.214-1.236c-0.323-0.06-0.513,0.224-0.772,0.392c-0.875,0.563-1.867,1.1-2.854,1.554
                  c0.73-0.776,1.987-1.259,2.904-1.911c0.862-0.612,0.47-1.529,0.479-2.279c0.013-1.081,0.313-2.321,0.646-3.37
                  c0.404-1.252,2.41-1.632,3.172-2.726c0.339-0.486,0.697-1.344,0.604-1.896c-0.074-0.43-1.363-1.463-1.334-1.608
                  c0.188-0.939-0.729-1.712-1.853-2.069c-1.193-0.382-2.248-0.161-3.312,0.34c-2.043,0.962-3.027,2.757-4.187,4.237
                  c-0.327,0.421-0.597,0.848-0.856,1.287c-0.208,0.348-0.861,0.967-0.83,1.354c0.021,0.243,0.391,0.557,0.549,0.77
                  c0.326,0.439,0.636,0.876,0.83,1.352c-0.117-0.473-0.896-1.979-1.713-1.926c1.008-2.368,3.47-4.375,5.228-6.459
                  c0.239-0.282,0.479-0.604,0.771-0.849c0.133-0.114,0.53-0.268,0.594-0.401c0.193-0.425-0.77-0.575-1.063-0.775
                  c-0.403-0.273-0.675-0.74-0.771-1.128c-0.274-1.115,0.855-1.577,1.123-2.187c0.434-0.99-0.04-1.722-1.268-2.2
                  c-0.625-0.243-1.184-0.298-1.813-0.328c-0.407-0.021-0.571,0.09-1.021-0.042c-1.146-0.337-2.229-0.904-3.313-1.352
                  c-1.145-0.471-2.396-0.83-3.613-1.188c-2.271-0.661-4.589-1.179-6.933-1.67c-2.301-0.482-4.572-1.1-7.008-0.923
                  c-1.396,0.101-4.137,0.462-4.5,1.684c-0.146,0.477,0.683,0.982,0.849,1.438c0.505,1.381-1.249,2.448-2.379,3.346
                  c-1.205,0.955-2.42,1.93-3.071,3.157c0.513,0.574,1.68,0.598,2.521,0.704c1.444,0.182,2.688,0.778,4.033,1.227
                  c2.771,0.922,6.026,2.091,8.139,3.734c0.771-1.062,1.787-1.956,3.109-2.712c-2.415,2.119-3.972,2.847-1.908,5.543
                  c-0.31-1.803-1.818-3.234-3.863-4.169c-3.172-1.45-12.409-6.517-14.45-1.671c1.201-0.983,1.646-2.396,2.709-3.475
                  c1.005-1.015,2.343-1.806,3.382-2.807c0.929-0.89,2.22-2.244,1.283-3.368c-0.964-1.167-2.751-0.625-4.137-0.463
                  c-5.049,0.595-8.37-0.413-12.979-1.694c-4.042-1.124-7.229-0.833-9.781,1.458c-1.074,0.964-2.479,1.626-2.622,2.775
                  c-0.135,1.026,0.292,2.035-0.032,3.157c-0.387,1.339-0.897,2.622-1.084,3.987c-0.269,1.951,0.919,3.696,0.811,5.642
                  c-0.068,1.33-1.059,2.726-0.792,4.043c0.151,0.036,0.289-0.014,0.438,0.034c0.533,0.743-0.057,1.456,0.337,2.319
                  c0.713,1.575,2.854,4.336,5.146,4.917c1.521,0.388,4.688,0.768,6.064-0.025c0.704-0.405,0.913-1.263,1.188-1.845
                  c0.469-0.987,1.361-2.337,2.543-2.971c2.944-1.586,7.521-0.078,10.063-2.183c0.283-0.234,0.564-0.498,0.823-0.746
                  c-0.446,0.466-1.066,0.978-1.299,1.514c0.021,0.016,0.043,0.03,0.063,0.048c1.141-0.131,2.487-0.234,3.657-0.158
                  c-0.734,0.249-1.882,0.1-2.722,0.199c-0.616,0.071-1.185,0.105-1.798,0.142c-0.979,0.059-1.876,0.391-2.834,0.466
                  c-0.758,0.061-1.556,0.021-2.322,0.078c-1.663,0.125-3.396,0.369-4.563,1.318c-0.598,0.484-0.75,1.67-1.121,2.266
                  c-0.282,0.455-1.182,1.212-1.296,1.681c-0.635,2.579,3.926,5.361,6.689,6.213c3.608,1.113,4.789-0.968,6.792-2.666
                  c2.536-2.151,5.523-3.816,8.919-5.178c1.286-0.515,1.614-0.899,1.604-1.992c0.057,0.391,0.021,0.825,0.232,1.186
                  c0.5-0.108,1.092-0.058,1.625-0.116c-2.071-0.039-3.896,1.046-5.497,1.891c-0.523,0.276-2.354,1.021-1.417,1.561
                  c0.599,0.347,1.322,0.234,2.016,0.317c0.809,0.098,1.645,0.311,2.436,0.468c0.895,0.179,4.096,0.727,2.821,1.651
                  c0.854-0.182,1.841,0.035,2.663,0.155c0.438,0.064,1.025,0.009,1.381,0.193c-0.023-0.297,0.224-0.882,0.339-1.23
                  c0.351-1.071,1.011-2.145,1.692-3.12c0.353-0.493,1.876-1.802,2.738-1.603c-1.252,0.197-2.162,1.154-2.768,1.914
                  c0.93-0.134,1.754-0.512,2.673-0.652c0.716-0.109,1.485-0.072,2.194-0.151c-1.387,0.155-2.684,0.374-4.063,0.688
                  c-0.637,0.146-0.837,0.175-1.145,0.601c-0.588,0.824-0.755,1.682-1.224,2.521c-0.161,0.292-0.414,0.627-0.331,0.963
                  c0.28,1.118,2.146,1.537,3.367,1.949c1.803,0.605,2.578,1.586,3.828,2.665c0.951,0.822,1.646,1.977,3.192,2.289
                  c1.565,0.319,2.97-0.47,4.394-0.774c-1.07,0.38-2.563,0.284-3.414,0.93c-0.996,0.755-1.813,2.511-1.479,3.511
                  c0.188-0.862,1.274-2.821,0.5-3.545c-0.372-0.348-1.348-0.591-1.821-0.946c-1.688-1.258-2.472-3.107-4.763-3.902
                  c-0.824-0.287-1.763-0.326-2.568-0.613c-1.039-0.367-1.063-1.387-2.238-1.646c-0.412-0.091-3.64-0.15-3.695-0.062
                  c0.13-0.204,0.396-0.342,0.706-0.383c-0.146-0.309-0.759-0.593-1.188-0.75c-2.063-0.755-5.7-1.381-7.979-0.893
                  c-1.433,0.308-2.563,1.055-3.451,1.902c-0.856,0.821-1.742,1.766-2.401,2.68c-0.396,0.546-0.122,1.041,0.516,1.396
                  c0.621,0.348,1.563,0.711,2.271,0.958c1.896,0.664,4.049,0.283,6.063,0.45c1.072,0.09,2.012,0.021,2.95,0.465
                  c0.992,0.471,1.625,1.289,2.336,1.937c-0.813-1.05-3.739-2.314-5.45-2.476c-2.125-0.2-4.093,0.075-6.199-0.465
                  c-1.585-0.405-5.61-2.306-7.056-0.825c-0.402,0.416-0.463,1.613-0.645,2.126c-0.324,0.915-1.001,2.073-0.781,3.021
                  c0.385,1.66,3.562,3.187,5.445,3.979c0.923,0.387,1.851,0.498,2.848,0.707c0.896,0.188,1.789,0.354,2.722,0.428
                  c1.282,0.101,2.593,0.067,3.878,0.129c1.038,0.053,2.063,0.196,3.098,0.251c1.313,0.069,2.688,0.156,4.004,0.153
                  c0.474,0,0.896-0.145,1.357-0.2c0.58-0.071,1.146-0.053,1.729-0.006c-0.785,0.078-1.979-0.058-2.673,0.212
                  c0.639,0.313,1.145,0.757,1.778,1.065c-0.502-0.285-0.938-0.619-1.512-0.849c-0.438-0.177-0.832-0.165-1.318-0.195
                  c-1.768-0.107-3.532-0.315-5.313-0.381c-1.995-0.076-3.914-0.239-5.876-0.494c-3.926-0.512-8.292-1.588-10.107-4.446
                  c-0.932-1.464,0.665-3.184,0.929-4.637c0.328-1.841-2.021-3.307-4.358-3.649c-0.994-0.146-2.545-0.512-3.552-0.268
                  c-0.939,0.226-1.894,1.174-2.673,1.603c-0.813,0.445-1.556,0.746-2.137,1.39c-1.548,1.71-0.153,3.265,2.414,3.517
                  c2.125,0.208,4.545-1.229,5.09-2.632c-0.342,0.78-1.188,1.389-2.112,1.875c-0.838,0.438-0.866,0.475-1.203,1.167
                  c-0.677,1.383-1.387,2.517-1.466,4.037c-0.063,1.216,0.271,3.067,1.521,3.897c1.646,1.092,3.856,0.214,5.636,0.595
                  c0.037-0.125,0.168-0.314,0.156-0.462c-0.004-0.039,0.06,0.305,0.063,0.385c-0.026-0.452-0.442-0.887-0.539-1.348
                  c1.304,1.988,4.014,3.331,6.715,4.242c2.675,0.904,5.854,0.874,8.717,0.594c2.173-0.212,3.96-0.143,6.104-0.012
                  c2.564,0.158,4.584,0.208,7.084-0.234c1.854-0.33,4.512-0.653,4.78-2.396c0.177-1.106,0.251-2.721-0.636-3.661
                  c-0.735-0.786-2.104-1.224-2.813-2.011c-0.449,0.329-1,0.111-1.641,0.217c-0.729,0.121-1.309,0.457-2.087,0.481
                  c-2.33,0.077-4.894-0.527-7.024-1.169c-1.604-0.48-3.271-0.572-4.844-1.123c-1.084-0.381-2.256-1.01-3.358-1.293
                  c-1.177-0.3-2.375,0.036-3.531,0.196c0.636,0.024,1.26-0.225,1.916-0.242c-0.253-0.378-0.577-0.815-0.665-1.223
                  c0.33,1.532,2.536,1.379,4.038,1.909c0.469,0.165,0.872,0.393,1.327,0.575c0.853,0.346,1.828,0.49,2.764,0.666
                  c0.889,0.166,1.793,0.278,2.664,0.49c0.776,0.188,1.467,0.509,2.215,0.747c0.988,0.314,1.968,0.352,3.041,0.406
                  c1.045,0.055,1.917-0.094,2.938-0.234c0.822-0.113,1.813-0.237,2.141-0.879c0.359-0.715,0.107-1.611-0.264-2.295
                  c-1.013-1.85-2.938-4.372-5.57-5.186c-0.688-0.214-1.012-0.44-1.59-0.751c-0.691-0.371-0.733-0.308-1.671-0.358
                  c-0.625-0.033-1.202-0.155-1.821-0.176c-0.729-0.022-1.392,0.112-2.126,0.119c0.905-0.436,2.173-0.354,3.103-0.127
                  c-0.428-0.35-0.61-0.84-1.023-1.208c0.238,0.371,0.773,1.108,1.272,1.318c0.425,0.179,1.071,0.032,1.526,0.128
                  c0.939,0.198,1.563,0.854,2.487,1.156c1.161,0.377,2.579,0.936,3.378,1.711c0.785,0.766,1.113,1.854,1.533,2.75
                  c0.373,0.798,0.875,1.568,0.916,2.419c0.029,0.7,0.229,1.174,0.947,1.702c1.188,0.875,2.926,1.448,4.526,1.783
                  c1.485,0.309,2.946,0.498,4.474,0.464c1.495-0.031,1.895-0.003,2.854-0.758c2.605-2.041,4.116-4.016,4.884-6.827
                  c0.191-0.7-0.254-1.527-0.879-2.118c-0.832-0.789-2.559-0.733-3.651-1.214c-0.005-0.005,0.052-0.092,0.042-0.12
                  c-3.028-0.893-5.932-1.483-6.178-4.18c-0.104-1.212,0.471-2.34,1.271-3.376c1.928-2.5-1.68-3.199-3.901-4.166
                  c-0.634-0.277-1.56-0.881-2.343-0.908c-1.311-0.046-2.283,0.859-3.533,0.939c-1.405,0.091-3.188-0.4-4.525-0.675
                  c-1.021-0.21-1.906-0.398-2.77-0.803c-0.873-0.413-1.562-0.689-2.603-0.321c-1.321,0.47-1.466,1.798-2.907,2.484
                  c-1.398,0.664-3.037,1.104-4.501,1.668c-1.572,0.604-4.913,1.534-5.512,2.877c-0.418,0.946-0.414,2.373,0.148,3.276
                  c-0.608-1.295-0.076-2.474-0.151-3.729c-0.031-0.55-0.228-0.569-0.873-0.802c-1.266-0.454-2.545-0.635-3.436-1.521
                  c0.678,0.761,2.767,1.548,4.007,1.725c0.725,0.104,2.141-0.512,2.845-0.702c1.565-0.426,2.98-1.164,4.443-1.744
                  c1.611-0.64,3.403-1.022,4.319-2.276c0.51-0.697,1.194-1.227,0.877-2.055c-0.271-0.686-1.124-1.33-1.886-1.747
                  c-0.854-0.465-1.821-0.837-2.825-1.093c-0.778-0.2-1.609-0.26-2.39-0.464c-0.688-0.18-1.274-0.48-1.979-0.652
                  c-1.432-0.35-3.001-0.345-4.491-0.287c-0.702,0.024-1.423,0.006-2.107,0.141c-0.66,0.131-1.342,0.304-1.979,0.114
                  c-1.344-0.398-1.926-1.745-1.991-2.678c-0.096-1.341,0.595-2.395,1.086-3.634c0.327-0.823,0.688-1.639,0.647-2.507
                  c-0.028-0.61-0.163-0.638-1.009-0.741c-0.741-0.09-1.504-0.141-2.254-0.083c-1.961,0.15-3.009,1.554-3.591,2.766
                  c-0.749,1.557-1.351,3.01-1.627,4.649c-0.223,1.302-0.278,2.474,0.043,3.759c0.299,1.179,0.191,2.236,2.188,2.062
                  c-0.391-0.081-0.876,0.104-1.323,0.025c0.459,1.034,1.243,2.104,1.962,3.057c-1.601-1.563-2.862-3.651-3.184-5.593
                  c-0.243-1.472,0.178-3.381,0.793-4.801c0.79-1.818,0.686-4.362,3.293-5.441c0.181-0.191,0.647-0.41,1.027-0.483
                  c-3.125,0.235-6.771-0.533-7.271-3.1c0.479,1.154,2.162,2.572,3.799,2.974c0.936,0.228,2.051,0.042,3.021,0.126
                  c0.646,0.057,1.173,0.046,1.848,0.046c1.249,0,2.479,0.149,3.688-0.097c1.649-0.338,3.508-1.006,4.988-1.621
                  c-1.761,0.49-3.383,1.261-5.102,1.817c-0.738,0.238-0.987,0.307-1.212,0.866c-0.271,0.682-0.307,1.396-0.48,2.093
                  c-0.171,0.674-0.6,1.249-0.85,1.905c-0.508,1.328-0.604,2.823,0.638,3.959c1.341,1.227,3.57,0.153,5.595,0.104
                  c2.644-0.063,4.333,0.781,6.61,1.438c1.396,0.4,3.183,0.58,4.397,1.257c1.147,0.64,1.604,1.59,3.001,2.116
                  c2.854,1.076,6.385,2.205,9.639,2.069c3.318-0.14,4.211-2.58,4.76-4.581c0.321-1.173,0.465-2.363,0.657-3.548
                  c0.179-1.1,0.695-2.099,0.81-3.203c0.279-2.771-2.258-3.021-5.544-3.844c-1.634-0.407-4.291-1.418-5.991-1.387
                  c-1.006,0.017-2.708,0.727-3.813,0.873c1.137-0.396,2.313-0.747,3.51-1.039c-2.271-0.564-1.792-1.571-3.171-2.76
                  c1.566,3.79,9.771,3.188,13.255,5.103c3.313,1.817,1.648,4.345,1.16,7.161c-0.257,1.489-0.621,3.111-1.293,4.534
                  c-0.63,1.336-1.104,1.451,0.286,2.364c0.703,0.461,1.731,0.514,2.565,0.825c1.615,0.601,3.641,1.669,3.021,3.223
                  c-0.396,0.999-1.133,1.897-1.396,2.961c-0.668,2.696,2.309,4.772,5.675,5.392c1.604,0.298,3.584,0.563,5.196,0.149
                  c2.308-0.59,2.143-2.012,3.556-3.196c-0.758-0.421-1.119-1.132-1.088-1.841c-0.138,2.812,7.263-0.571,8.411-1.129
                  c2.783-1.348,3.129-3.424,4.607-5.453c1.26-1.726,0.248-2.357-0.204-4.319c-0.479-2.077,0.803-4.052,1.204-6.075
                  c0.563-2.811,1.217-9.186-4.043-9.833c-1.65-0.204-3.638,0.369-4.826,1.286c-0.678,0.521-0.921,1.2-1.797,1.577
                  c-1.3,0.562-2.777-0.142-4.004,0.48c1.287-0.188,2.587-0.342,3.873-0.533"/>
          </Tooltip>
        )
    }
}

export default SmallIntestine
