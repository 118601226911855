import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

class Femur extends Component {

    render() { const {onClick, fillColor} = this.props
        return (
          <Tooltip
            title="Femur"
            placement="right">
          <path
            id="femur"
            fill={fillColor} onClick={onClick}
            fillOpacity="0.5"
            stroke="#787878"
            strokeWidth="0.5"
            d="M257.48,508.726
              c-0.492-0.339-1.063-1.086-1.521-1.979c-0.668-1.318-0.744-1.662-0.868-3.902c-0.113-2.055-0.064-2.672,0.305-3.771
              c0.51-1.521,1.604-2.849,2.369-2.873c0.418-0.021,0.527-0.15,0.502-0.608c-0.019-0.32,0.113-0.734,0.293-0.924
              c0.821-0.854,2.217-4.766,4.371-12.241l2.336-8.104l4.754-52.146c4.489-49.233,4.732-52.153,4.354-52.44
              c-0.854-0.646-1.146-3.021-0.828-6.984c0.225-2.854-0.118-7.857-0.71-10.417c-0.188-0.807-0.479-2.085-0.646-2.846
              c-0.729-3.351-2.647-5.436-5.422-5.865c-3.805-0.595-6.58-1.703-7.619-3.051c-0.604-0.786-0.861-2.041-0.674-3.223
              c0.146-0.859,0.088-1.064-0.521-1.983c-0.979-1.47-0.632-2.679,1.117-3.901c0.517-0.362,1.069-0.806,1.242-0.986
              c0.513-0.541,1.521-1.103,2.364-1.317c1.322-0.334,2.399,0.162,4.675,2.155c3.507,3.07,7.146,5.352,10.026,6.275
              c1.295,0.416,3.563,0.722,3.549,0.479c-0.006-0.086,0.545-0.11,1.229-0.056c1.478,0.119,1.974,0.621,2.275,2.307
              c0.359,1.953,2.729,4.556,4.49,4.945c1.25,0.275,2.189,1.878,2.563,4.356c0.262,1.754-0.072,3.333-1.078,5.104
              c-0.662,1.165-1.946,2.216-2.755,2.252c-0.519,0.022-1.282,1.237-1.813,2.855c-0.297,0.896-1.24,13.394-4.125,54.595
              c-2.061,29.392-3.771,55.316-3.813,57.621c-0.162,9.705,0.428,18.646,1.333,20.187c0.615,1.052,1.813,1.774,3.049,1.854
              c0.87,0.058,1.284,0.252,2.104,0.992c1.207,1.091,2.373,3.302,2.817,5.348c0.396,1.813,0.074,3.175-1.139,4.84
              c-1.22,1.674-2.085,2.07-4.856,2.238c-2.688,0.163-2.947,0.024-3.938-2.047c-0.561-1.166-0.579-1.188-1.926-1.388
              c-4.461-0.687-10.588,0.801-11.971,2.888c-0.813,1.227-2.063,1.951-3.713,2.151C258.465,509.247,258.162,509.193,257.48,508.726
              L257.48,508.726z"/>
          </Tooltip>
        )
    }
}

export default Femur
